import 'vite/modulepreload-polyfill';
import './scss/main.scss';
import './css/generated/generated.css';
import { domReady } from './functions/DomReady';
import { initHeader } from '@/functions/headerFunctions';
import { initFooter } from "@/functions/footerFunctions";
import { initGlobalStorageEvents } from '@/Utilities/misc';
import {initLinkList} from '@/functions/Content/linkListFunction';
import { initFairCalendar } from '@/functions/fairCalendar/fairCalendarFunctions';
import {ThemeModifier} from 'lms-components/src/Utilities/ThemeModifier';

// import './Classes/Configuration';
// import {initTouchClass} from '@/Classes/Utility/DetectTouchController';
// import {detectAdBlockController} from '@/Classes/Utility/DetectAdBlockController';

if (import.meta.hot) {
  import.meta.hot.on('vite:afterUpdate', () => {
    const event = new CustomEvent('RerenderTicketshop');
    window.dispatchEvent(event);
  });
}

initGlobalStorageEvents();
ThemeModifier.setThemeColors();

domReady(async () => {
    // initTouchClass();
    // detectAdBlockController();

  const header: HTMLElement | null = document.querySelector('.new-header');
  const footer: HTMLElement | null = document.querySelector('.new-footer');
  const linkList: NodeListOf<HTMLElement> | null = document.querySelectorAll('.lms-linkList');
  const fairCalendar: HTMLElement | null = document.querySelector('.lms-fair-calendar');

  if (header) {
    initHeader(header);
  }
  if (footer) {
    initFooter(footer);
  }

  if (linkList?.length) {
    linkList.forEach((linkListItem) => {
      initLinkList(linkListItem);
    });
  }

  if (fairCalendar) {
    document.querySelector('.wrapper')?.classList.add('wrapper--fairCalendar');
    initFairCalendar(fairCalendar);
  }
});
